<template lang="">
<div class="p-fluid">
  <div class="p-field p-grid">
    <label for="localname" class="p-col-3">Name:</label>
    <div class="p-col">
      <InputText id="localname" type="text" v-model="localName" />
    </div>
  </div>
  <div class="p-field p-grid">
    <div class="p-col-3"></div>
    <div class="p-col">
     <SelectButton v-model="localIsActive" :options="isActiveOptions" optionLabel="name" optionValue="code"></SelectButton>
    </div>
  </div>
  <div class="p-field p-grid">
    <label for="localdescription" class="p-col-3">Description:</label>
    <div class="p-col">
     <TextArea v-model="localDescription" rows="10"></TextArea>
    </div>
  </div>
  <div class="p-field p-grid">
    <label class="p-col-3">Tags:</label>
    <div class="p-col">
      <Chips v-model="localTags" separator="," />
    </div>
  </div>
  <div class="p-field p-grid">
    <label class="p-col-3">Classification:</label>
    <div class="p-col">
      <Dropdown 
        v-model="localClassification"
        :options="abilityClassOptions"
        optionLabel="text"
        optionValue="value"
        placeholder="Select a classification"
        :showClear="true" />
    </div>
  </div>
  <div v-if="isActive">
    <div class="p-field p-grid">
      <label class="p-col-3">AP Cost:</label>
      <div class="p-col">
        <InputNumber v-model="localApcost" />
      </div>
    </div>
    <div class="p-field p-grid">
      <label class="p-col-3">Mana Cost:</label>
      <div class="p-col">
        <InputNumber v-model="localManacost" />
      </div>
    </div>
    <div class="p-field p-grid">
      <label class="p-col-3">Alt Resource:</label>
      <div class="p-col">
        <InputText type="text" v-model="localAltresource" />
      </div>
    </div>
    <div class="p-fluid" v-if="localAltresource">
      <div class="p-field p-grid">
        <label class="p-col-3">Alt Res Cost:</label>
        <div class="p-col">
          <InputNumber v-model="localAltcost"  />
        </div>
      </div>
    </div>
    <div class="p-field p-grid">
      <label class="p-col-3">Range:</label>
      <div class="p-col">
        <Dropdown 
          v-model="localRange"
          :options="rangeOptions"
          optionLabel="text"
          optionValue="value"
          placeholder="Select a range"
          :showClear="true" />
      </div>
    </div>
    <div class="p-field p-grid" v-if="localRange == 'Spell'">
      <label class="p-col-3">Range Value:</label>
      <div class="p-col">
        <InputNumber v-model="localRangevalue" suffix="ft" />
      </div>
    </div>
    <div class="p-field p-grid">
      <label class="p-col-3">Target:</label>
      <div class="p-col">
        <InputText type="text" v-model="localTarget" />
      </div>
    </div>
    <div class="p-field p-grid">
      <label class="p-col-3">Duration:</label>
      <div class="p-col">
        <InputText type="text" v-model="localDuration" />
      </div>
    </div>
    <div class="p-field p-grid">
      <label class="p-col-3">Resistance:</label>
      <div class="p-col">
        <InputText type="text" v-model="localResistance" />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import SelectButton from 'primevue/selectbutton'
import Dropdown from 'primevue/dropdown'
import TextArea from 'primevue/textarea'
import Chips from 'primevue/chips'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
      InputText,
      InputNumber,
      SelectButton,
      Dropdown,
      TextArea,
      Chips,
  },
  data() {
    return {
        rangeOptions: null,
        abilityClassOptions: null,
        isActiveProxy:"isNotActive",
        isActiveOptions:[
          {name:'Passive',code:'isNotActive'},
          {name:'Active',code:'isActive'},
        ],
    };
  },
  props: {
    name: String,
    isActive: Boolean,
    description: String,
    tags: String,
    classification: String,
    apcost: Number,
    manacost: Number,
    altresource: String,
    altcost: Number,
    range: String,
    rangevalue: Number,
    target: String,
    duration: String,
    resistance: String,
    uid: String,
    createdAt: Object,
    lastModified: Object,
    docid: {type:String, default:null},
  },
  computed: {
    ...mapGetters([
      'abilities',
      'getAbilityById',
      'user',
      'lookups',
      'fbTimestamp',
    ]),
    localName: {
      get() {return this.name},
      set(name) {this.$emit('update:name',name)}
    },
    localIsActive: {
      get() {
        if(this.isActive)
          return "isActive";
        else
          return "isNotActive";
      },
      set(isActive) {
        let isActiveBool = isActive == "isActive";
        this.$emit('update:isActive',isActiveBool)}
    },
    localDescription: {
      get() {return this.description},
      set(description) {this.$emit('update:description',description)}
    },
    localTags: {
      get() {
        if(this.tags) {
          return this.tags.split(',');
        }
        else {
          return [];
        }
      },
      set(tags) {
        if(Array.isArray(tags)) {
          this.$emit('update:tags',tags.join(','))
        } else {
          this.$emit('update:tags','')
        }
      }
    },
    localClassification: {
      get() {return this.classification},
      set(classification) {this.$emit('update:classification',classification)}
    },
    localApcost: {
      get() {return this.lclParseInt(this.apcost)},
      set(apcost) {this.$emit('update:apcost',this.lclParseInt(apcost))}
    },
    localManacost: {
      get() {return this.lclParseInt(this.manacost)},
      set(manacost) {this.$emit('update:manacost',this.lclParseInt(manacost))}
    },
    localAltresource: {
      get() {return this.altresource},
      set(altresource) {this.$emit('update:altresource',altresource)}
    },
    localAltcost: {
      get() {return this.lclParseInt(this.altcost)},
      set(altcost) {this.$emit('update:altcost',this.lclParseInt(altcost))}
    },
    localRange: {
      get() {return this.range},
      set(range) {this.$emit('update:range',range)}
    },
    localRangevalue: {
      get() {return this.lclParseInt(this.rangevalue)},
      set(rangevalue) {this.$emit('update:rangevalue',this.lclParseInt(rangevalue))}
    },
    localTarget: {
      get() {return this.target},
      set(target) {this.$emit('update:target',target)}
    },
    localDuration: {
      get() {return this.duration},
      set(duration) {this.$emit('update:duration',duration)}
    },
    localResistance: {
      get() {return this.resistance},
      set(resistance) {this.$emit('update:resistance',resistance)}
    },

  },
  methods: {
    ...mapActions([
      'fetchAbilities',
      'fetchLookups',
    ]),
    lclParseInt(intVal) {
      if(!intVal || intVal == "") {
        return null;
      } else {
        return parseInt(intVal);
      }
    },
  },
  created() {
    let loadPromises = [];
    if(!this.lookups) {
      loadPromises.push(this.fetchLookups());
    }
    Promise.all(loadPromises).then(() => {

      const rangeTypeArray = this.lookups.abilityRangeType.types;
      this.rangeOptions = [];
      rangeTypeArray.forEach(range => {
        this.rangeOptions.push({text: range,value:range});
      })

      const abilityClassTypeArray = this.lookups.abilityClassType.types;
      this.abilityClassOptions = [];
      abilityClassTypeArray.forEach(abilityClass => {
        this.abilityClassOptions.push({text: abilityClass,value:abilityClass});
      })
    });
  },
  emits: [
    'update:name',
    'update:isActive',
    'update:description',
    'update:tags',
    'update:classification',
    'update:apcost',
    'update:manacost',
    'update:altresource',
    'update:altcost',
    'update:range',
    'update:rangevalue',
    'update:target',
    'update:duration',
    'update:resistance',
  ]
};
</script>

<style lang="scss">
label {
  font-weight:700;
}
</style>